@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.container {
  z-index: 9;
  margin: 0;
  padding: 0;
  list-style: none;
  cursor: pointer;
  user-select: none;

  @include from(2) {
    width: 200px;
    border: 1px solid $grey75;
    border-radius: 3px;
    background: $white;
    box-shadow: 0 0 8px transparentize($black, 0.94);
  }

  @include to(1) {
    width: 100vw;
    height: 100vh;
    background-color: transparentize($black, 0.5);
    text-align: right;
  }

  > li {
    @include to(1) {
      padding: 0 var(--profile-menu-padding-inline, 0) 28px;
      background: $white;

      &:first-child {
        padding-top: 28px;
      }

      &:last-child {
        box-shadow: 0 8px 8px transparentize($black, 0.94);
      }
    }

    @include from(2) {
      margin: 3px 0;
    }
  }
}

.nameItem {
  @include from(2) {
    display: none;
  }
}

.name {
  padding: 10px 22px;
  font-weight: 600;
  cursor: default;
}

.link {
  display: block;
  padding: 10px 22px;
  color: $fontColor;

  &:hover {
    background: $grey25;
  }
}
