@import 'colors.scss';

$shadowSpread: 10px;

@keyframes toast-lower {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes toast-raise {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.container {
  display: flex;
  position: fixed;
  z-index: 1000;
  align-items: center;
  justify-content: center;
  width: 100%;
  overflow: hidden;
  animation-duration: 1s;
  box-shadow: 0 1px $shadowSpread $grey500;
  color: $fontColorLight;
  text-align: center;
}

.inner {
  padding: 15px 20px;
}

.container :local {
  animation-name: toast-lower;
}

.ok {
  background: $ok;
}

.error {
  background: $error;
}

.warning {
  background: $warningDark;
  color: $grey700;
}

.closing {
  animation-fill-mode: forwards;
}

.closing :local {
  animation-name: toast-raise;
}
