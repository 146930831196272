@import 'breakpoints.scss';
@import 'colors.scss';

.subheader {
  font-weight: 600;
  text-align: center;

  @include from(3) {
    margin-bottom: 42px;
    font-size: 24px;
  }

  @include to(2) {
    margin-bottom: 20px;
    font-size: 22px;
    line-height: 23px;
  }
}

.light {
  color: $grey500;
}

.dark {
  color: $white;
}
