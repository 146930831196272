@import 'breakpoints.scss';
@import 'sizes.scss';
@import 'colors.scss';

.main {
  display: flex;
  flex: 1 0;
  flex-direction: column;

  @include from(2) {
    min-height: calc(100vh - #{$headerHeight});
  }

  @include to(1) {
    min-height: calc(100vh - #{$headerHeightResponsive});
  }
}
