@import 'colors.scss';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
  padding: 12px;
  border: 1px solid themeColor('waybetter', 'secondary');
  border-radius: 5px;
  background-color: themeColor('waybetter', 'light');
}

.icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  margin-right: 12px;
  border-radius: 50%;
  background-color: themeColor('waybetter', 'secondary');
  color: $white;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
}

.message {
  flex: 1;
  font-size: 15px;
  line-height: 20px;
}
