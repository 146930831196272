@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.passwordRules {
  margin: 12px 0 24px;
  color: $grey900;
  font-size: 13px;
  line-height: 16px;
  text-align: center;
}
