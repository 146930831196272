@import 'colors.scss';

.container {
  display: flex;
  color: $fontMedium;
  font-size: 15px;
  white-space: nowrap;
}

.arrow {
  height: 20px;
  margin-top: 2px;
}
