@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.modalView {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 980px !important;

  @include to(2) {
    position: absolute !important;
    top: 32px;
    max-width: 90% !important;
  }

  h2 {
    color: $grey600;
    font-size: 25px;
    line-height: 30px;
  }

  button {
    width: 100%;
  }
}

.modalDescription {
  color: $grey900;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
