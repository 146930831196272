@import 'breakpoints.scss';
@import 'colors.scss';
@import 'utils.scss';

.container {
  display: flex;
  flex-direction: column;

  @include from(3) {
    width: 600px;
    margin-bottom: 20px;
  }

  @include to(2) {
    margin-bottom: 11px;
  }
}

.info {
  display: flex;
  margin-bottom: 8px;
}

.avatar {
  width: 40px;
  height: 40px;
  margin-right: 11px;
}

.poster {
  line-height: 12px;
}

.primaryPoster {
  color: $fontColor;
  font-weight: 600;
}

.postDate {
  color: $grey400;
  font-size: 13px;
  line-height: 12px;
}

.content {
  margin: 0 0 18px;
  color: $grey900;
  line-height: 19px;
}

.content a {
  transition: color 0.25s ease-out;
  text-decoration: none;
  cursor: pointer;
}

@each $theme, $themeColors in $themes {
  .#{$theme} .content a {
    color: map-get($themeColors, 'secondary');

    &:hover {
      color: map-get($themeColors, 'hover');
    }
  }
}

.media {
  object-fit: contain;

  @include from(3) {
    max-width: calc(100% + 40px);
    margin: 0 -20px 18px;
  }

  @include to(2) {
    max-width: calc(100% + 24px);
    margin: 0 -12px 18px;
  }
}

.others {
  display: flex;
  justify-content: space-between;
  color: $grey400;
  font-size: 13px;
  line-height: 12px;
}
