.button.button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.label {
  white-space: nowrap;
}

.loading.loading {
  display: inline-flex;
  flex: 0 0 2.6667em;
  height: 2.6667em;
  margin: -1.3334em -0.6667em -1.3334em 0.3333em;
  padding: 0;
}
