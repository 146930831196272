@import 'colors.scss';

.member {
  color: $membership;
}

.memberIcon {
  height: 1em;
  margin-left: 3px;
}
