@import 'colors.scss';

.container {
  display: flex;
}

.input {
  width: 100%;
}

.inputWithButton.inputWithButton {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.inputInner {
  height: 100%;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border: 1px solid $border;
  border-left: 0;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  background-color: $grey25;
}

.icon {
  width: 16px;
}
