@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  padding: 25px 0 40px;
  text-align: center;
}

.faqs {
  margin: 0 auto 20px;

  @include from(3) {
    max-width: 450px;
  }
}

.faq {
  margin: 0;
  text-align: left;
}

.faqHeading {
  margin: 0;
}

.links {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  > :first-child {
    margin-right: 12px;
  }
}

.link.link {
  width: 150px;
  font-size: 14px;
}

.chat {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chatBubble {
  width: 25px;
  height: 24px;
  margin-right: 0.5em;
}
