@import 'colors.scss';

.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

@each $theme, $themeColors in $themes {
  $secondary: map-get($themeColors, 'secondary');

  .#{$theme} {
    background-color: $secondary;

    .number {
      color: $fontColorLight;
    }
  }

  .#{$theme}.inverted {
    background-color: $white;

    .number {
      color: $secondary;
    }
  }
}

.number {
  font-weight: 600;
}
