@import 'breakpoints.scss';
@import 'colors.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.value {
  font-size: 34px;
  font-weight: 600;
}

.description {
  font-size: 17px;
}
