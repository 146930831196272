@import 'breakpoints.scss';
@import 'colors.scss';

%heading {
  margin: 0 0 10px;
}

%subheading {
  margin: 0 0 20px;
}

.heading {
  @extend %heading;

  font-size: 36px;

  @include to(2) {
    display: none;
  }
}

.subheading {
  @extend %subheading;

  @include to(2) {
    display: none;
  }
}

.responsiveHeading {
  @extend %heading;

  font-style: italic;

  @include from(3) {
    display: none;
  }
}

.responsiveSubheading {
  @extend %subheading;

  margin-bottom: 30px;

  @include from(3) {
    display: none;
  }
}

.cta {
  min-height: auto;
  max-height: 110px;
  padding: 0;

  @include from(3) {
    width: 200px;
    margin: auto 0 10px;
  }

  @include to(2) {
    margin: 0 0 10px;
  }
}

.transformerSubtext {
  color: themeColor('dietbet', 'transformer');
  font-weight: 600;
}

.kickstarterSubtext {
  color: themeColor('dietbet', 'kickstarter');
  font-weight: 600;
}
